<template>
  <div class="container mt-5 mb-2">
    <div class="row">
      <div class=" col-12 col-md-8 offset-md-2 text-center">
        <div>
          <h5 class="text-uppercase mb-3 ">VISION</h5>
          <p class="">
            To be the most innovative and trustes premier finacial services
            provider
          </p>
        </div>
        <div class="my-5">
          <h5 class="text-uppercase mb-3 ">MISSION</h5>
          <p class="">
            To provide flexible and creative solutions to customers and generate
            graeter values to our stakeholders while assuring corporate
            governance through an emprowered professional team.
          </p>
        </div>
        <div>
          <h5 class="text-uppercase mb-3 ">
            FAST LANKA MICRO CREDIT (Registration No: - PV00229556)
          </h5>
          <p class="">
            is an innovative retail finance institution in Sri Lanka. FAST LANKA
            MICRO CREDIT provides quick loans, investment and other financial
            services through our various channels including internet. Our
            outstanding products and services are created to suit the financial
            requirements of our diverse clientele. We commenced business in 2016
            and have since delivered retail financial services in an
            unprecedented manner to our esteemed customers. We continuously
            strive to understand our clients' needs so we can provide efficient
            financial solutions and opportunities. At FAST LANKA MICRO CREDIT,
            we strongly believe in integrity, empathy, respect, innovation and
            creating value in everything we do. Our vision is to remain the
            leading technologically innovative retail financial institution in
            Sri Lanka. Our services are delivered in a targeted way that puts
            into consideration our corporate relationships and client's
            preferences because we place great importance in sustaining lasting
            relationships with our clients throughout their financial lives. We
            have a team of like-minded people, mutually driven, supportive of
            each other, friendly, creative and positively motivated towards
            working together productively to create value whether first to
            ourselves then to our client-base, investors and community at large
            with the single goal of being exceptional by all standards in our
            relationships.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "About Us" }]);
  }
};
</script>
